<template>
    <b-container v-if="epoRows" fluid>
        <b-row class="my-4">
            <b-col md="6">
                <div v-if="date_from" class="py-2 print-show">Datum od: {{date_from | moment("DD.MM.YYYY.")}}</div>
                <b-form-group :label-cols-md="4"
                              label="Datum od:"
                              label-for="date_from">
                    <date-picker format="DD.MM.YYYY."
                                 v-model="date_from"
                                 :lang="$parent.hrv"
                                 :first-day-of-week="1"
                                 class="w-100"
                                 id="date_from">
                    </date-picker>
                </b-form-group>
            </b-col>
            <b-col md="6">
                <div v-if="date_to" class="py-2 print-show">Datum do: {{date_to | moment("DD.MM.YYYY.")}}</div>
                <b-form-group :label-cols-md="4"
                              label="Datum do:"
                              label-for="date_to">
                    <date-picker format="DD.MM.YYYY."
                                 v-model="date_to"
                                 :lang="$parent.hrv"
                                 :first-day-of-week="1"
                                 class="w-100"
                                 id="date_to">
                    </date-picker>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <table class="table b-table table-striped table-hover table-bordered text-center align-middle">
                    <thead>
                    <tr>
                        <th></th>
                        <th>
                            Obrazac EPO-1044 <br/>
                            <h3>Evidencija potraživanja i obaveza</h3>
                        </th>
                        <th>Stranica {{epoRows.current_page}} od  {{epoRows.last_page}}</th>
                    </tr>
                    </thead>
                </table>
            </b-col>
        </b-row>
        <b-row class="text-left my-2">
            <b-col>
                <table class="table b-table table-striped table-hover table-bordered text-left align-middle">
                    <thead v-if="selectedClient">
                    <tr>
                        <th>Podaci o poreznom obvezniku ili nosiocu zajedničke djelatnosti:</th>
                        <th>Podaci o registrovanoj djelatnosti:</th>
                    </tr>
                    <tr>
                        <td><b>1. JMB: </b>{{selectedClient.owner.jmbg}}</td>
                        <td><b>4. JIB: </b>{{selectedClient.id_number}}</td>
                    </tr>
                    <tr>
                        <td><b>2. Prezime i ime: </b>{{selectedClient.owner.name}}</td>
                        <td><b>5. Naziv: </b>{{selectedClient.name}}</td>
                    </tr>
                    <tr>
                        <td><b>3. Adresa sjedišta: </b>{{selectedClient.owner.address}}</td>
                        <td><b>6. Adresa prebivališta: </b>{{selectedClient.address}}</td>
                    </tr>
                    </thead>
                </table>
            </b-col>
        </b-row>
        <b-row class="flex-center my-2">
            <h4>Podaci o potražvanjima i obavezama</h4>
        </b-row>
        <b-row>
            <b-col>
                <roza-table :items="epoRows"
                            :fields="epoFields"
                            :prenosFields="prenosFields"
                            :prenosColspan="4"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :actions="actions"
                            @action="action"
                            actionHide="appended"
                            class="table-sm">
                    <template slot="custom-header">
                        <tr>
                            <th colspan="4" rowspan="2"></th>
                            <th colspan="5">PREGLED IZDATIH RAČUNA</th>
                            <th colspan="5">PREGLED PRIMLJENIH RAČUNA</th>
                            <th colspan="2" rowspan="2"></th>
                        </tr>
                        <tr>
                            <th colspan="3">Izdato</th>
                            <th colspan="2">Naplaćeno</th>
                            <th colspan="3">Obveza</th>
                            <th colspan="2">Plaćeno</th>
                        </tr>
                    </template>
                    <template slot="custom-header2">
                        <tr>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>a</th>
                            <th>9</th>
                            <th>10</th>
                            <th>11</th>
                            <th>12</th>
                            <th>13</th>
                            <th>14</th>
                            <th>15</th>
                            <th>16</th>
                            <th>17</th>
                            <th>18</th>
                            <th>19</th>
                            <th>20</th>
                        </tr>
                    </template>
                    <template slot="invoice_number" slot-scope="data">
                        <template v-if="(data.item.konto_id < 11 && data.field.type === 0)
                                        || (data.item.konto_id > 10 && data.field.type === 1)">
                            {{data.item.number}}
                        </template>
                    </template>
                    <template slot="invoice_date_slot" slot-scope="data">
                        <template v-if="data.item.invoice_date" >
                            <template v-if="(data.item.konto_id < 11 && data.field.type === 0)
                                        || (data.item.konto_id > 10 && data.field.type === 1)">
                                {{data.item.invoice_date | moment("DD.MM.YYYY.")}}
                            </template>
                        </template>
                    </template>
                    <template slot="total" slot-scope="data">
                        <template v-if="data.item.invoice_date" >
                            <template v-if="data.item.total_credit === '0,00' && data.field.type === 0">
                                {{data.item['total_debit']}}
                            </template>
                            <template v-else-if="data.item.total_debit === '0,00' && data.field.type === 1">
                                {{data.item['total_credit']}}
                            </template>
                        </template>
                    </template>
                    <template slot="datumi" slot-scope="data">
                        <template v-for="i in data.item.entry_order_rows">
                            <template v-if="i.type === 1">
                                <template v-if="(i.konto_id < 8 && data.field.type === 0)
                                        || ([12,13,15].includes(i.konto_id) && data.field.type === 1)">
                                    {{i.invoice_date | moment("DD.MM.YYYY.")}}<br/>
                                </template>
                            </template>
                        </template>
                    </template>
                    <template slot="iznos" slot-scope="data">
                        <template v-for="i in data.item.entry_order_rows">
                            <template v-if="i.type === 1">
                                <template v-if="i.konto_id < 8 && data.field.type === 0">
                                    {{i.credit}}<br/>
                                </template>
                                <template v-else-if="[12,13,15].includes(i.konto_id) && data.field.type === 1">
                                    {{i.debit}}<br/>
                                </template>
                            </template>
                        </template>
                    </template>
                </roza-table>

                <b-pagination @input="getRows"
                              align="right"
                              v-if="epoRows"
                              :total-rows="epoRows.total"
                              v-model="currentPage"
                              :per-page="perPage">
                </b-pagination>
            </b-col>
        </b-row>
        <set-index-modal
                ref="setIndex"
                index="epo_index"
                :item="selectedRow">
        </set-index-modal>
    </b-container>
</template>

<script>

    export default {
        name: 'EpoForm',
        data() {
            return {
                epoRows: null,
                date_from: null,
                date_to: null,
                epoFields: [
                    {
                        key: 'epo_index',
                        label: 'Broj'
                    },
                    {
                        key: 'associate_code',
                        label: 'Šifra sur.'
                    },
                    {
                        key: 'associate_name',
                        label: 'Naziv kupca ili dobavljača'
                    },
                    {
                        key: 'document_date',
                        label: 'Datum dokumenta',
                        date: true
                    },
                    {
                        key: 'invoice_number',
                        type: 0,
                        label: 'Broj računa',
                    },
                    {
                        key: 'invoice_date_slot',
                        type: 0,
                        label: 'Datum izdavanja',
                        date: true
                    },
                    {
                        key: 'total',
                        type: 0,
                        label: 'Iznos u KM',
                        class: 'text-right'
                    },
                    {
                        key: 'datumi',
                        type: 0,
                        label: 'Datum'
                    },
                    {
                        key: 'iznos',
                        type: 0,
                        label: 'Iznos',
                        class: 'text-right'
                    },
                    {
                        key: 'invoice_number',
                        type: 1,
                        label: 'Broj računa',
                    },
                    {
                        key: 'invoice_date_slot',
                        type: 1,
                        label: 'Datum prijema',
                        date: true
                    },
                    {
                        key: 'total',
                        type: 1,
                        label: 'Iznos obaveze',
                        class: 'text-right'
                    },
                    {
                        key: 'datumi',
                        type: 1,
                        label: 'Datum'
                    },
                    {
                        key: 'iznos',
                        type: 1,
                        label: 'Iznos',
                        class: 'text-right'
                    },
                    {
                        label: 'Redni broj u knjizi prihoda i rashoda',
                        thClass: 'col-max-width',
                        class: 'col-max-width'
                    }
                ],
                prenosFields: [
                    {
                        colspan: 2
                    },
                    'totalDebit',
                    '',
                    'totalCharged',
                    {
                        colspan: 2
                    },
                    'totalCredit',
                    '',
                    'totalPaid',
                    ''
                ],
                actions: {
                    index: {
                        name: 'Dodijeli redni br.',
                        icon: 'fa fa-hashtag'
                    }
                },
                deleteRowId: 0,
                currentPage: 1,
                perPage: 10,
                selectedRow: null
            }
        },
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            getRows() {
                axiOsta.get(api.clients + this.selectedClient.id + api.epoRows, {
                    params: {
                        page: this.currentPage,
                        perPage: this.perPage,
                        from: this.date_from,
                        to: this.date_to,
                        year: this.$cookie.get('ezint-year')
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.epoRows = response.data;
                    }
                })
            },
            action: function (action, item) {
                switch (action) {
                    case 'index':
                        this.selectedRow = item;
                        this.$refs.setIndex.show();
                        break;
                }
            },
        },
        created() {
            if (this.selectedClient != null) {
                this.getRows();
            }
            eventBus.$on('year-changed', () => {
                this.getRows();
            });
        },
        mounted() {
            this.$on('get-rows', () => this.getRows())
        },
        watch: {
            selectedClient: function (newValue, oldValue) {
                if (oldValue == null) {
                    this.getRows();
                }
            },
            date_from: function () {
                this.getRows();
            },
            date_to: function () {
                this.getRows();
            }
        }
    }
</script>